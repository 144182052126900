.anualOrTakenLeaveContainer {
  display: flex;
  gap: 45px;
}

.anualOrTakenLeaveTitle {
  max-width: 120px !important;
  width: 100% !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  white-space: nowrap;
}

.statisticsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fui-TableRow:hover {
  background-color: #fff !important;
}

.statisticsHeader {
  width: auto;
  border-bottom: 1px solid #dbdbdb;
}

.statisticsRow {
  border-bottom: none !important;
}

.statisticsValuesContainer {
  border-bottom: none !important;
}

.statisticsItem {
  width: auto;
  height: 31px;
  font-weight: 400;
  font-size: 12px;
  line-height: 31px;
  color: #5c5b5b;
  padding-left: 0px;
}

.statisticsValue {
  width: 16px;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
  color: #252525;
  padding-left: 0px;
}

@media only screen and (max-width: 768px) {
  .anualOrTakenLeaveContainer {
    margin-bottom: 16px;
    flex-direction: column;
    gap: 6px;
  }

  .statisticsContainer {
    gap: 8px;
  }

  .statisticsMobRow {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 0.5px solid #dbdbdb;
    width: 100%;
  }

  .statisticsItem {
    height: auto;
    font-size: 14px;
    line-height: 21px;
    flex-basis: 50%;
  }

  .statisticsValue {
    height: auto;
    line-height: 21px;
    width: auto;
  }
}
