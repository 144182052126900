.attendanceLogHoursContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);

  .attendanceLogHoursContent {
    display: flex;
    flex-direction: row;
    height: 64px;
    justify-content: space-between;
    align-items: center;

    .attendanceLogHoursTitle {
      height: 20px;
      left: 20px;
      position: relative;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }

    .expandedAttendanceLogHoursTitle {
      height: 23px;
      left: 20px;
      position: relative;
      color: #1877a2;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 3px solid #1877a2;
    }

    .attendanceLogHoursActions {
      display: flex;
      align-items: center;
      position: relative;
      right: 20px;
      gap: 12px;

      .attendanceLogHoursButton {
        width: 120px;
        height: 32px;
        border-radius: 4px;
        padding: 0 12px 0 12px;
        gap: 4px;
        color: #fff !important;
      }
    }
  }

  .expandedContent {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .react-datepicker__day--selected {
      background-color: #1877a2;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: transparent;
    }

    .attendanceLogHoursInputContainer {
      display: flex;
      flex-direction: column;

      .fui-Select__select {
        background-color: #eefafd !important;
      }
    }

    .attendanceHoursInputDurationContainer {
      justify-content: center;
    }

    .defaultAttendanceLogHoursLabel {
      font-weight: 400;
      font-size: 12px;
      color: #616161;
      padding-bottom: 7px;
    }

    .attendanceLogHoursInput,
    .attendanceLogHoursDatePickerInput {
      height: 32px;
      display: flex;
      gap: 20px;
      background-color: #eefafd !important;
      border: 1px;
      border-radius: 3px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #242424;
      border: none !important;
    }

    .attendanceLogHoursDatePickerInput {
      width: 142px;
      padding-left: 10px;
    }

    .attendanceLogHoursDateInput {
      width: 100px;
      height: 32px;
      padding-bottom: 12px;
      display: flex;
      background-color: #fff !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #acacac !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid #dddddd !important;
    }

    .attendanceLogHoursInput:focus,
    .attendanceLogHoursDateInput:focus,
    .attendanceLogHoursDatePickerInput:focus {
      outline: none;
      border-bottom: 2px solid #1877a2;
    }

    .datePickerContainer {
      position: relative;
    }

    .inputClock {
      position: absolute;
      top: 33%;
      right: 3px;
      transform: translateY(-50%);
      height: auto;
    }

    .error-message {
      color: red;
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .addTimeContainer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding: 30px 0 20px 0;
  }
  .saveTrackLogHours {
    display: flex;
    justify-content: end;
    margin: 0 20px 20px 0;
  }
  .addTimeButton {
    height: 32px;
    padding: 0 12px 0 12px !important;
    gap: 4px;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #fff !important;
  }
}

.attendanceLogHoursForm {
  width: fit-content;
}

.hourDifference {
  max-width: 43px;
  height: 24px;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  gap: 4px;
  background-color: #1877a2;
}

.hourDifferenceText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}

.personioRowContainer {
  display: flex;
  gap: 20px;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .addTimeContainer {
    align-self: center;
  }
}

@media only screen and (max-width: 768px) {
  .attendanceLogHoursForm {
    width: 100%;
  }
  .attendanceLogHoursFormContainer {
    .expandedContent {
      flex-direction: column !important;
      left: 0;
    }
  }

  .personioRowContainer {
    flex-direction: column !important;
  }

  .attendanceLogHoursContainer {
    padding: 12px;
    .attendanceLogHoursContent {
      width: 100%;

      .expandedAttendanceLogHoursTitle {
        left: 0;
      }

      .attendanceLogHoursActions {
        right: 0;
      }
    }

    .expandedContent {
      .attendanceLogHoursInputContainer {
        width: 100% !important;
      }

      .attendanceLogHoursDatePickerInput {
        width: 100%;
      }
    }
  }
}
