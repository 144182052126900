.organigramSettingsPage {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .organigramSettingsInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #252525;
  }

  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(36, 36, 36, 1);
  }

  .subdescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(97, 97, 97, 1);
  }

  .detailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .details {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(97, 97, 97, 1);
    }
  }
}

.organigramSettingsOrganigramSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: auto;
  height: 100%;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.organigramSettingsOrganigramRow {
  display: flex;
  gap: 10px;
  min-width: max-content;
  margin: 0 auto;
}

.organigramSettingsUserCard {
  background-color: #eefafd;
  border-radius: 4px;
  padding: 16px;
  min-width: 350px;
}

.organigramSettingsUserCardHeader {
  display: flex;
  gap: 8px;
  align-items: center;
}

.organigramSettingsUserCardName {
  font-weight: 700;
  font-size: 14px;
  color: rgba(36, 36, 36, 1);
  white-space: nowrap;
}

.organigramSettingsUserCardJobTitle {
  font-weight: 400;
  font-size: 12px;
  color: rgba(97, 97, 97, 1);
  white-space: nowrap;
}

.organigramSettingsUserCardTags {
  display: flex;
  gap: 4px;
  margin-top: 12px;
}

.organigramSettingsUserCardTag {
  background-color: #d4f2f9;
  padding: 2px 6px 2px 6px;
  border-radius: 100px;
  color: #1e506c;
  font-size: 10px;
  text-transform: uppercase;
  white-space: nowrap;
}

.organigramSettingsOrganigramSection::-webkit-scrollbar {
  height: 7px;
}

.organigramSettingsOrganigramSection::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.organigramSettingsOrganigramSection::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

@media only screen and (max-width: 768px) {
  .organigramSettingsOrganigramSection {
    align-items: flex-start;
  }
}
