.contactUsFormContainer {
  display: flex;
  flex-direction: column;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  gap: 16px;
  flex-basis: 50%;
  width: 100%;

  .contactUsInformationContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .formTitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #242424;
    }

    .formDescription {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #616161;
    }
  }

  .contactUsForm {
    .contactUsInputContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .contactUsInputLabel {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #616161;
      margin-top: 12px;
    }

    .contactUsFormFirstSection {
      display: flex;
      gap: 4px;
      flex-direction: row;
      justify-content: space-between;

      .contactUsLighterInput {
        min-width: 212px;
        height: 32px;
        border-radius: 3px;
        color: #fff;
      }
    }

    .contactUsFormSecondSection {
      display: flex;
      flex-direction: column;

      .contactUsFilledInput {
        min-width: 436px;
        height: 32px;
        border-radius: 4px;
        background-color: #eefafd;
        border: none !important;
      }
    }

    .contactUsFormThirdSection {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding-top: 40px;
      justify-content: center;
      align-items: center;
    }

    .sendContactUsForm {
      color: #fff;
    }
  }
}

@media only screen and (max-width: 768px) {
  .contactUsFormContainer {
    .contactUsForm {
      .contactUsFormFirstSection {
        flex-direction: column;
      }

      .contactUsFormSecondSection {
        .contactUsFilledInput {
          min-width: auto;
        }
      }
    }
  }
}
