.overlapsModalContainer {
  width: auto;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .overlapsModalContent {
    display: grid;
    margin: 30px 20px 70px 20px;
  }

  .overlapsTable {
    width: auto;

    .overlapsHeaderRow {
      background-color: #eefafd !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #242424;
    }

    .overlapsTableRow {
      display: flex;
      margin-left: -10px;
      gap: 10px;
    }

    .fui-Avatar__initials {
      background-color: #fbebcc !important;
      font-size: 12px;
    }

    .overlapsNameContainer {
      display: flex;
      flex-direction: column;
    }

    .overlapsLabelSection {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .overlapsDepartmentSection {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }

    .overlapsTablePeriodContainer {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }

    .overlapsTablePeriodDaysContainer {
      background-color: #d4f2f9 !important;
      width: auto;
      padding: 0 8px 0 8px;
      height: 16px;
      border-radius: 100px;
      gap: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .overlapsTablePeriodDays {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #1877a2;
    }
    .overlapsTableOverlapSection {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
    .overlapsTableLabelSection {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .overlapIcon {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .overlapsModalContainer {
    .overlapsModalContent {
      margin: 10px;
      overflow-x: auto;
    }

    .overlapsModalContent::-webkit-scrollbar {
      height: 7px;
    }

    .overlapsModalContent::-webkit-scrollbar-thumb {
      background-color: #d6d6d6;
      border-radius: 4px;
    }

    .overlapsModalContent::-webkit-scrollbar-thumb:hover {
      background-color: #bfbfbf;
    }

    .overlapsTable {
      .overlapsLabelSection {
        white-space: nowrap;
      }
      .overlapsTablePeriodDays {
        white-space: nowrap;
      }
      .overlapsTableLabelSection {
        white-space: nowrap;
      }
    }
  }
}
