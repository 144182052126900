.microsoftSettingsPage {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1000px;

  .microsoftSettingsInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #252525;
  }

  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(36, 36, 36, 1);
  }

  .subdescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(97, 97, 97, 1);
  }

  .detailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .details {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(97, 97, 97, 1);
    }
  }

  .settingsSyncNowBtn {
    margin-top: 10px;
    width: 111px;
    height: 32px;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
}
