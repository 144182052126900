.addEmployeeModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 70px;
}

.addEmployeeForm {
  margin: 20px 20px 20px 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .formFirstLine,
  .formSecondLine,
  .formThirdLine,
  .formFourthLine,
  .formFourthLinePersonio,
  .formFifthLine {
    display: flex;
    gap: 12px;
  }

  .formFourthLinePersonio {
    width: 100%;
    max-width: 562px;
  }

  .formLastLinePersonio {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    max-width: 562px;
  }

  .addEmployeeManagementInputStyle {
    display: flex;
    min-width: 182px;
    height: 32px;
    border-radius: 3px;
    gap: 20px;
    background-color: #eefafd !important;
    border: 1px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    border: none !important;
  }

  .addEmployeeManagementNumberInputStyle {
    display: flex;
    width: 135px;
    height: 32px;
    border-radius: 3px;
    gap: 20px;
    background-color: #eefafd !important;
    border: 1px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    border: none !important;
  }

  .addEmployeeInputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .addEmployeeInputContainerPersonio {
    width: 50%;
  }

  .addEmployeeInputContainer5thPersonio {
    width: 33%;
  }

  .addEmployeeInputLabel {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
    margin-top: 12px;
  }

  .formFirstLine {
    flex-direction: row;
  }

  .formThirdLine {
    .addEmployeeManagementDatePickerInput {
      height: 32px;
      padding-left: 10px;
    }

    .addEmployeeManagementDatePickerInput:focus {
      outline: none;
      border-bottom: 2px solid #1877a2;
    }

    .datePickerContainer {
      position: relative;
    }

    .addEmployeeManagementDateInput:focus {
      outline: none;
      border-bottom: 2px solid #1877a2;
    }

    .inputClock {
      position: absolute;
      top: 53%;
      right: 10px;
      transform: translateY(-50%);
      height: auto;
    }
  }

  .formSixthLine {
    margin-top: 20px;
    display: flex;
    justify-content: end;
    .addEmployeeAttendanceButton {
      height: 32px;
      border-radius: 4px;
      padding: 0 12px 0 12px;
      gap: 4px;
      color: #fff !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .addEmployeeForm {
    .addEmployeeManagementInputStyle {
      width: 100%;
    }

    .addEmployeeManagementNumberInputStyle {
      width: 100% !important;
    }

    .formFirstLine {
      flex-direction: column;
    }

    .formSecondLine {
      flex-direction: column;
    }

    .formThirdLine {
      flex-direction: column;
    }

    .formFourthLine {
      flex-direction: column;
    }

    .formFourthLinePersonio {
      flex-direction: column;
    }

    .formFifthLine {
      flex-direction: column;
    }
  }
}
