.personioSettingsPage {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1000px;

  .personioSettingsInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #252525;
    }

    .subtitle {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: rgba(36, 36, 36, 1);
    }

    .subdescription {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(97, 97, 97, 1);
    }

    .detailsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .details {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(97, 97, 97, 1);
      }
    }

    .settingsSyncNowBtn {
      margin-top: 10px;
      width: 111px;
      height: 32px;
      color: white;
      border-radius: 4px;
      display: flex;
      align-items: center;
    }

    .personioProfilesSection {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      gap: 32px;
      width: 100%;
    }

    .personioProfileContainer {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 12px;
    }

    .personioProfileCard {
      border: 1px solid rgba(179, 176, 173, 1);
      border-radius: 4px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }

    .personioProfileCardDetailsSection {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .personioProfileCardButtons {
      display: flex;
      gap: 8px;
    }

    .personioProfileActionDisconnect {
      border-radius: 4px;
      border: 1px solid rgba(209, 209, 209, 1);
      font-size: 12px;
      font-weight: 600;
      color: rgba(36, 36, 36, 1);
      width: 77px;
    }

    .personioProfileActionConnect {
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
      color: white;
      width: 61px;
    }

    .selected {
      border-color: rgba(91, 95, 199, 1);
    }

    .personioProfileCardDetailsContainer {
      display: flex;
      flex-direction: column;
    }

    .personioProfileCardTitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: rgba(36, 36, 36, 1);
    }

    .personioProfileCardEmail {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba(97, 97, 97, 1);
    }

    .personioRadioContainer {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .personioProfileRadioInput {
      height: 16px;
      width: 16px;
      margin-bottom: 2px;
    }

    .personioProfileRadioLabel {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(104, 104, 104, 1);
    }

    .personioDataSection {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .personioDataTitle {
        font-size: 12px;
        color: rgba(104, 104, 104, 1);
      }

      .personioDataRadioSection {
        display: flex;
        flex-direction: column;
      }

      .personioDataRadioContainer {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .personioDataRadioInput {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
        margin-bottom: 2px;
      }

      .personioDataRadioLabel {
        font-size: 14px;
        line-height: 20px;
        color: rgba(104, 104, 104, 1);
      }

      .personioDataActionsSection {
        margin-top: 12px;
        display: flex;
        gap: 8px;
        height: 32px;
      }

      .personioDataActionDisconnect {
        border-radius: 4px;
        border: 1px solid rgba(209, 209, 209, 1);
        font-size: 14px;
        font-weight: 600;
        color: rgba(36, 36, 36, 1);
        width: 77px;
      }

      .personioDataActionConnect {
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        color: white;
        width: 61px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .personioSettingsPage {
    .personioSettingsInfoContainer {
      .personioProfilesSection {
        flex-direction: column;
      }

      .personioProfileCard {
        width: 100%;
      }
    }
  }
}
