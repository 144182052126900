.summarizedModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 70px;
}

.summarizedModalTableContainer {
  margin-top: 15px;
}

.summarizedModalTableContainer::-webkit-scrollbar {
  height: 7px;
}

.summarizedModalTableContainer::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.summarizedModalTableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}
