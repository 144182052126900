.employeeInfoContainer {
  display: flex;
  flex-direction: column;
  /* height: 40px; */

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #252525;
  }

  .detailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .details {
      max-width: 400px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
    .employeesTableActionButtons {
      display: flex;
      gap: 18px;

      .refreshContainer {
        display: flex;
        gap: 6px;
        align-items: center;

        .rvgcg50 {
          color: #1877a2 !important;
        }
      }
      .refreshButton,
      .addButton {
        display: flex;
        background: none;
        border: none;
        gap: 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        color: #1877a2;
        padding: 0;
        cursor: pointer;
      }

      .addButton {
        color: #242424;
      }
    }
  }
}

.detailedAttendanceTableActionButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  gap: 18px;

  .refreshContainer {
    display: flex;
    gap: 6px;
    align-items: center;

    .rvgcg50 {
      color: #1877a2 !important;
    }
  }
  .refreshButton,
  .addButton {
    display: flex;
    background: none;
    border: none;
    gap: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #1877a2;
    padding: 0;
    cursor: pointer;
  }

  .addButton {
    color: #242424;
  }
}

.employeesTableContainer {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 5px;
  overflow-x: auto;

  .employeesTable {
    width: auto;
    /* width: 500px; */
    margin-bottom: 10px;

    .employeesHeaderRow {
      background-color: #eefafd !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #242424;
    }

    .f1p9o1ba {
      overflow-x: unset;
    }

    .fui-Avatar__initials {
      background-color: #fbebcc !important;
      font-size: 12px;
    }

    .employeesNameContainer {
      display: flex;
      flex-direction: column;
    }

    .employeesPeriodContainer {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }

    .employeesLabelSection {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .overlapIcon {
      cursor: pointer;
    }
    .employeesDepartmentSection {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .employeesActionsCell {
    justify-content: end;
  }

  .employeesActionsSection {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: end;

    .actionIcon {
      cursor: pointer;
      justify-content: end;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.employeesTableContainer::-webkit-scrollbar {
  height: 7px;
}

.employeesTableContainer::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.employeesTableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}
