.absencesSideNav {
  background-color: #fff;
  color: #333;
  width: 201px;
  height: 94.4vh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  border-right: 2px solid #f4f4f4;
  display: none;
}

.absencesSideNav.absencesOpen {
  transform: translateX(0);
  display: block;
}

.sideNavMenuList {
  width: 192px;
  height: 128px;
  top: 20px;
  left: 4px;
  list-style-type: none;
  padding-left: 8px;
}

.sideNavMenuItem {
  width: auto;
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 40px;
  border-radius: 4px;
  font-weight: 400;
  padding: 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.menuItemName {
  display: flex;
  align-items: center;
  height: 20px;
  top: 5px;
  left: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sideNavMenuItem.active {
  background-color: #eefafd;
  font-weight: 700;
}

.toggleSideNavBtn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .toggleSideNavBtn {
    padding: 5px;
    display: block;
    position: absolute;
    border: 1px solid #1877a2;
    border-radius: 5px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .hideToggleSidebar {
    display: none;
  }

  .absencesSideNav {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    padding-top: 30px;
    z-index: 5;
  }

  .hideContent {
    display: none;
  }

  .sideNavMenuList {
    width: 100%;
    padding-right: 8px;
  }

  .closeSidebarNav {
    display: none;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }

  .showSidenavClose {
    display: block;
  }
}
