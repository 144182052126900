.pagination {
  margin-top: 15px;
}

.directionButton {
  border: 0;
  padding: 0;
  background-color: #fff !important;
}

.arrowButton {
  cursor: pointer;
}

.separator {
  margin: 0 5px 0 5px;
}
