.absenceRequestContainer {
  flex-direction: row;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.absenceRequestForm {
  width: 100%;
}

.absenceRequestContent {
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: space-between;
  align-items: center;
}

.notExpandedContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.absenceRequestTitle {
  height: 20px;
  left: 20px;
  position: relative;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.expandedAbsenceRequestTitle {
  height: 23px;
  left: 20px;
  position: relative;
  color: #1877a2;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 3px solid #1877a2;
}

.absenceRequestActions {
  display: flex;
  align-items: center;
  position: relative;
  right: 20px;
  gap: 12px;
}

.absenceRequestButton {
  height: 32px;
  border-radius: 4px;
  padding: 0 12px 0 12px;
  gap: 4px;
  color: #fff !important;
}

.expandedContent {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: 19px;
  flex-wrap: wrap;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.inputField {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.absencesInputContainer {
  display: flex;
  flex-direction: column;
  width: calc((100% - 60px) / 3);

  .fui-Select__select {
    background-color: #eefafd !important;
  }
}

.absencesInputContainerPersonio {
  width: calc((100% - 60px) / 2);
}

.absencesPersonioIntervalContainer {
  display: flex;
  gap: 20px;
}

.absencesPersonioIntervalContainerInput {
  width: calc((100% - 20px) / 2);
}

.submitButton {
  background-color: #0078d4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #005a9e;
}

.requestAbsenceButton {
  display: flex;
  height: 32px;
  top: 168px;
  padding: 0 12px 0 12px !important;
  gap: 4px;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #fff !important;
}

.requestAbsenceContainer {
  width: auto;
  display: flex;
  justify-content: center;
  padding: 30px 0 20px 0;
}

.absencesInput {
  width: 100%;
  height: 32px;
  display: flex;
  gap: 20px;
  background-color: #eefafd !important;
  border: 1px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
  padding-left: 10px;
  border: none !important;
}

.absencesInput:focus {
  outline: none;
  border-bottom: 2px solid #1877a2;
}

.react-datepicker__close-icon::after {
  background-color: #1877a2 !important;
  padding: 0 !important;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}

.requestSentContainer {
  display: flex;
  height: 32px;
  top: 26px;
  left: 336px;
  border-radius: 4px;
  border: 1px solid #bdda9b;
  padding: 6px 14px 6px 14px;
  background-color: #e7f2da;
}

.requestSentMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #237b4b;
  gap: 2px;
}

.absenceRequestFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.daysLeftContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  gap: 4px;
  background-color: #00ad56;
}

.daysLeftText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  padding-right: 4px;
}

.datePickerLabel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 4px 0;
}

.datePickerFotter {
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.defaultAbsencesRequestLabel {
  font-weight: 400;
  font-size: 12px;
  color: #616161;
}

.defaultAbsencesRequestSecondLabel {
  font-weight: 400;
  font-size: 12px;
  color: #616161;
  padding-bottom: 7px;
}

.selectedDaysNumber {
  width: 46px;
  height: 16px;
  border-radius: 100px;
  padding: 2px 6px 2px 6px;
  gap: 2px;
  background-color: #d4f2f9;
  margin-left: auto;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #1b6183;
}

@media screen and (max-width: 768px) {
  .expandedContent {
    flex-direction: column;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .requestAbsenceContainer {
    align-self: center;
  }
  .cc {
    width: 100%;
    align-items: center;
  }

  .absenceRequestForm {
    width: 100%;
  }

  .infoContainer {
    height: auto !important;
  }

  .absencesInputContainer {
    width: 100%;
  }

  .absencesPersonioIntervalContainer {
    flex-direction: column;
    width: 100%;
  }

  .absencesPersonioIntervalContainerInput {
    width: 100%;
  }
}
