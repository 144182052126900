.usersFiltersModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.usersFiltersModal {
  background-color: #ffffff;
  padding: 20px 20px 0 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 4px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  min-width: 360px;
  position: relative;
}

.usersFiltersModal::-webkit-scrollbar {
  width: 7px;
}

.usersFiltersModal::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.usersFiltersModal::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

.usersFiltersModalHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.usersFiltersModalTitle {
  font-size: 18px;
  font-weight: 700;
  color: rgba(50, 49, 48, 1);
}

.users-filters-modal-close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
}

.usersFiltersModalContentSection {
  display: flex;
  flex-direction: column;
}

.usersFilterSectionTitle {
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.usersFilterSectionTitleNumber {
  color: #1877a2;
}

.users-filters-modal-close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
}

.usersFilterSectionCheckboxesContainer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-top: 12px;
  padding-bottom: 12px;
}

.usersFilterSection {
  cursor: pointer;
}

.usersFiltersSeeMoreBtn {
  font-size: 14px;
  font-weight: 400;
  color: rgba(91, 95, 199, 1);
}

.usersFiltersSeeMoreBtn:hover {
  cursor: pointer;
}

.usersFiltersModalActionButtons {
  border-top: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  position: sticky;
  bottom: -2px;
  background-color: white;
  padding-bottom: 20px;
}

.usersClearAllButton {
  font-size: 14px;
  font-weight: 400;
  color: #1877a2;
}

.usersClearAllButton:hover {
  cursor: pointer;
  color: #1b6183;
}

.usersFiltersModalButtons {
  display: flex;
  gap: 8px;
}

.usersApplyButton {
  border-radius: 4px !important;
  height: 32px !important;
  color: #ffffff !important;
  gap: 6px;
}

@media only screen and (max-width: 768px) {
  .usersFiltersModal {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
