.addEmployeeModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 70px;
}

.addEmployeeAttendanceForm {
  margin: 20px 20px 20px 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .formFirstLine,
  .formSecondLine,
  .formThirdLine,
  .formFourthLine,
  .formFifthLine {
    display: flex;
    gap: 12px;
  }

  .formSecondLinePersonio,
  .formFourthLinePersonio {
    display: flex;
    gap: 12px;
  }

  .addEmployeeAttendanceInputStyle {
    display: flex;
    border-radius: 3px;
    gap: 20px;
    background-color: #eefafd !important;
    border: 1px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    border: none !important;
  }

  .addEmployeeAttendanceInputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .addEmployeeAttendanceInputLabel {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
    margin-top: 12px;
  }

  .formFirstLine {
    .addEmployeeAttendanceDatePickerInput {
      width: 536px;
      height: 32px;
      padding-left: 10px;
    }

    .addEmployeeAttendanceDatePickerInput:focus {
      outline: none;
      border-bottom: 2px solid #1877a2;
    }
  }

  .formSecondLine {
    .addAttendanceSelectInput {
      width: 262px;
      height: 32px;
      border-radius: 3px;
      background-color: #eefafd !important;
    }
  }

  .formSecondLinePersonio {
    width: 100%;
  }

  .addEmployeeAttendanceInputContainerPeronio {
    width: 50%;
  }

  .formThirdLinePersonio {
    .addAttendanceSelectLocationInput {
      width: 536px;
      border-radius: 3px;
      background-color: #eefafd !important;
    }
  }

  .formThirdLine {
    .addAttendanceSelectLocationInput {
      width: 536px;
      height: 32px;
      border-radius: 3px;
      background-color: #eefafd !important;
    }
  }

  .formFourthLine {
    .datePickerContainer {
      position: relative;
    }

    .addEmployeeAttendanceDateInput {
      width: 262px;
      height: 32px;
      padding-bottom: 12px;
      display: flex;
      background-color: #fff !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #acacac !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid #dddddd !important;
    }

    .addEmployeeAttendanceDateInput:focus {
      outline: none;
      border-bottom: 2px solid #1877a2;
    }

    .inputClock {
      position: absolute;
      top: 33%;
      right: 3px;
      transform: translateY(-50%);
      height: auto;
    }
  }

  .formFourthLinePersonio {
    .datePickerContainer {
      position: relative;
    }

    .addEmployeeAttendanceDateInput {
      height: 32px;
      padding-bottom: 12px;
      display: flex;
      background-color: #fff !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #acacac !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid #dddddd !important;
    }

    .addEmployeeAttendanceDateInput:focus {
      outline: none;
      border-bottom: 2px solid #1877a2;
    }

    .inputClock {
      position: absolute;
      top: 33%;
      right: 3px;
      transform: translateY(-50%);
      height: auto;
    }
  }

  .formFifthLine {
    display: flex;
    align-items: center;
    justify-content: center;
    .hourDifference {
      max-width: 43px;
      height: 24px;
      border-radius: 4px;
      padding: 4px 8px 4px 8px;
      margin-top: 12px;
      gap: 4px;
      background-color: #1877a2;
    }

    .hourDifferenceText {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #fff;
    }
  }

  .formSixthLine {
    margin-top: 80px;
    display: flex;
    justify-content: end;
    .addEmployeeAttendanceButton {
      height: 32px;
      border-radius: 4px;
      padding: 0 12px 0 12px;
      gap: 4px;
      color: #fff !important;
    }
  }

  .fui-Select__select {
    background-color: #eefafd !important;
  }
}

@media only screen and (max-width: 768px) {
  .addEmployeeAttendanceForm {
    .addEmployeeAttendanceInputContainer {
      width: 100%;
    }

    .formFirstLine {
      .addEmployeeAttendanceDatePickerInput {
        width: 100%;
      }
    }

    .formSecondLine {
      flex-direction: column;
      gap: 0;
      .addAttendanceSelectInput {
        width: 100%;
      }
    }

    .formThirdLine {
      .addAttendanceSelectLocationInput {
        width: 100%;
      }
    }

    .addAttendanceSelectLocationInput {
      width: 100%;
    }

    .formFourthLine {
      flex-direction: column;
    }
  }
}
