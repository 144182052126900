.step4PersonioContainer {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.step4PersonioHeaderContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.step4HeaderTextSection {
  flex-grow: 1;
}

.step4PersonioTitleSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.step4PersonioTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #242424;
  margin-bottom: 0;
}

.step4PersonioSubtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #616161;
  margin-top: 0;
}

.step4PersonioSectionTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.step4PersonioSection2Title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
  margin-bottom: 0;
}

.step4PersonioSetup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.step4PersonioSetupForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.step4PersonioSetupInputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.step4PersonioSetupLabel {
  font-size: 12px;
  color: rgba(97, 97, 97, 1);
}

.step4PersonioSetupInput {
  width: 100%;
  max-width: 500px;
  height: 32px;
  display: flex;
  gap: 20px;
  background-color: #eefafd !important;
  border: 1px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
  padding-left: 10px;
  border: none !important;
}

.step4PersonioSetupSubmitBtn {
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  .step4PersonioHeaderContainer {
    flex-direction: column;
  }
}
