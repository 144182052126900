.footer-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-top: 2px solid #f4f4f4;
  background-color: #fff;
  color: #333;
  bottom: 0;
}

.nextButton {
  width: 96px;
  height: 32px;
  border-radius: 4px;
  padding: 0 12px 0 12px;
  gap: 4px;
  color: #fff !important;
}

.cancelButton,
.skipButton {
  width: 96px;
  height: 32px;
  border-radius: 4px;
  padding: 0 12px 0 12px;
  gap: 4px;
  background-color: #ffffff !important;
  color: #000 !important;
  border: 1px solid #c7c7c7 !important;
}

.rightButtons {
  display: flex;
  gap: 4px;
}
