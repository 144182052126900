.usersRoleDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 180px;
  height: 26px;
  border-radius: 4px !important;
  gap: 4px;
  background-color: #ebebeb !important;
  border: none !important;
  outline: none !important;
  min-width: auto;
}

.usersRoleDropdown::after {
  border-bottom: none !important;
  outline: none !important;
}
