.cardContainer {
  width: 324px;
  height: 244px;
  border-radius: 4px;
  gap: 4px;
  background-color: #fff;
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.11);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .contactUsCardImage {
    width: 324px;
    height: 160px;
  }

  .cardInfoContainer {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;

    .cardTitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #242424;
    }

    .linksContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .cardLink {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1877a2;
      }
    }
  }
}
