.absencesLayout {
  display: flex;
  flex-direction: column;
}

.absencesContentLayout {
  display: flex;
}

.mainAbsencesContentLayout {
  background-color: #fff;
  color: #333;
  flex: 1;
  padding: 20px;
  /* width: 100%; */
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .absencesLayout {
    height: 100%;
  }
  .mainAbsencesContentLayout {
    margin-top: 40px;
    width: 100%;
    padding: 12px;
    height: 100%;
  }
}
