.stepContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.step1Container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  flex-grow: 1;
}

.titleSection,
.hrSystemDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.step1Title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #242424;
  margin-bottom: 0;
}

.step1Subtitle,
.hrSystemSubtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #616161;
  margin-top: 0;
}

.hrSystem {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.hrSystemTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
  margin-bottom: 0;
}

.otherHrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 372px;
  height: 218px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
}

.otherHrLabel {
  font-weight: 400 !important;
  padding-top: 8px;
  padding-bottom: 8px;
}

.otherHrInput {
  width: 372px;
  height: 32px;
  border-radius: 4px;
}

.sendRequestButton {
  width: 275px;
  height: 32px;
  border-radius: 4px;
  padding: 0 12px 0 12px;
  color: #fff !important;
}

.sendRequestMessage {
  font-weight: 450;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.fui-RadioGroup {
  margin-left: -6px;
}

@media only screen and (max-width: 768px) {
  .stepContainer {
    flex-direction: column;
  }
}
