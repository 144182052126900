.addEmployeeModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 70px;
}

.addEmployeeForm {
  margin: 20px 20px 20px 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .addEmployeeFormFirstLine,
  .addEmployeeFormSecondLine,
  .addEmployeeFormThirdLine,
  .addEmployeeFormFourthLine {
    display: flex;
    gap: 12px;
  }

  .addEmployeeInputStyle {
    display: flex;
    border-radius: 3px;
    gap: 20px;
    background-color: #eefafd !important;
    border: 1px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    border: none !important;
  }

  .addEmployeeInputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .addEmployeeInputLabel {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
    margin-top: 12px;
  }

  .addEmployeeDatePickerInput {
    width: 262px;
    height: 32px;
    padding-left: 10px;
  }
  .addEmployeeDatePickerInput:focus {
    outline: none;
    border-bottom: 2px solid #1877a2;
  }

  .addEmployeeFormFirstLine {
    .addEmployeeSelectTypeInput {
      width: 262px;
      height: 32px;
      border-radius: 3px;
    }
  }

  .addEmployeeFormSecondLine {
    .addEmployeeOverlapInput {
      width: 124px;
      height: 32px;
    }
    .addEmployeeStatusInput {
      width: 124px;
      height: 32px;
    }

    .addEmployeeStatusInput.Accepted {
      background-color: #a5d6a7 !important;
    }

    .addEmployeeStatusInput.Pending {
      background-color: #f8d22a !important;
    }

    .addEmployeeStatusInput.Canceled {
      background-color: #ef9a9a !important;
    }

    .inputWithImage {
      display: flex;
      align-items: center;
    }

    .inputWithImage .fluentui-input-wrapper {
      flex: 1;
      margin-right: 10px;
    }

    .acceptedStatus {
      height: 30px;
    }
  }

  .addEmployeeFormThirdLine {
    .addEmployeeThirdLineInputs {
      width: 170px;
      height: 32px;
    }
  }

  .addEmployeeFormGeneralError {
    text-align: center;
    color: rgba(196, 49, 75, 1);
  }

  .addEmployeeFormFourthLine {
    .addEmployeeCommentInput {
      width: 536px;
      height: 72px;
    }
  }
  .addEmployeeFormFifthLine {
    margin-top: 24px;
    display: flex;
    justify-content: end;
    .addEmployeeButton {
      height: 32px;
      border-radius: 4px;
      padding: 0 12px 0 12px;
      gap: 4px;
      color: #fff !important;
    }
  }
  .fui-Select__select {
    background-color: transparent !important;
  }
}

@media only screen and (max-width: 768px) {
  .addEmployeeForm {
    .addEmployeeFormFirstLine,
    .addEmployeeFormSecondLine,
    .addEmployeeFormThirdLine,
    .addEmployeeFormFourthLine {
      flex-direction: column;
    }

    .addEmployeeDatePickerInput,
    .addEmployeeSelectTypeInput,
    .addEmployeeOverlapInput,
    .addEmployeeStatusInput,
    .addEmployeeThirdLineInputs,
    .addEmployeeCommentInput {
      width: 100% !important;
    }
  }
}
