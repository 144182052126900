.welcome {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.narrow {
  width: 100vh;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-padding {
  padding: 4rem;
}

.welcome.page > .narrow > img {
  margin: 0 auto;
  display: block;
  width: 200px;
}

.center {
  text-align: center;
}

.welcomeMessage {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.completeMessage {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.getStartedButton {
  width: 280px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.error {
  color: red;
}
