body,
#root {
  width: 100% !important;
  height: 100% !important;
}

.configPageWrapper {
  min-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.configTitle {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
