.attendanceActions {
  display: flex;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  align-items: center;
  gap: 36px;
  margin-bottom: 24px;
  height: 64px;
}

.attendanceActionsButtons {
  display: flex;
  gap: 24px;
  margin-left: 20px;
}

.checkInButton {
  height: 32px;
  width: 120px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;

  .checkInButtonContent {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #fff;
    white-space: nowrap;
  }

  .fui-Radio__indicator {
    border: 1px solid #fff !important;
  }

  .fui-Radio__input:checked + .fui-Radio__indicator::after {
    background-color: #fff;
  }
}

.checkOutButton {
  height: 32px;
  width: 120px;
  border-radius: 4px;
  border: none !important;

  .checkOutButtonContent {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #c7c7c7;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

.attendanceActionsTimer {
  display: flex;
  gap: 24px;
  align-items: center;
}

.timerContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.timerDuration {
  color: #1877a2;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.toggleBtn {
  background-color: #3ab3db;
  border: 1px solid #aaa;
  border-radius: 99px;
  width: 46px;
  height: 24px;
  cursor: pointer;
  position: relative;
}

.toggleBtn .thumb {
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 99px;
  transform: translateX(0);
  transition: left 0.15 ease;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.toggleBtn.toggled .thumb {
  left: calc(50px - 27px);
}

.toggleMessage {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #686868;
  white-space: nowrap;
  margin-right: 8px;
}

@media only screen and (max-width: 768px) {
  .attendanceActions {
    flex-direction: column !important;
    height: auto !important;
    gap: 24px;
  }
}

@media only screen and (max-width: 800px) {
  .attendanceActions {
    gap: 24px;

    .attendanceActionsButtons {
      display: flex;
      gap: 16px;
    }

    .attendanceActionsTimer {
      display: flex;
      gap: 16px;
    }
  }
}
