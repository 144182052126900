.aboutPageContainer {
  display: flex;
  flex-direction: column;
  gap: 48px;
  background-color: #d4f2f9;
  padding-top: 60px;
  padding-bottom: 60px;
  align-items: center;
}

.aboutPageHeaderContainer {
  padding-left: 60px;
  padding-right: 60px;
}

.aboutPageHeaderTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.aboutPageHeaderTitle {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  color: #1877a2;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  background: rgba(200, 84, 255, 0.14);
  width: fit-content;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 12px;
}

.aboutPageHeaderDescription {
  font-size: 20px;
  text-align: center;
  line-height: 40px;
}

.aboutPageSection2Card {
  background-color: white;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.aboutPageSection2ImageContainer {
  text-align: center;
  max-width: 100%;
}

.aboutPageSection2CardTitle {
  font-size: 20px;
  font-weight: 500;
}

.aboutPageSection2CardDescription {
  font-size: 16px;
  line-height: 28px;
}

.aboutPageSection3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  max-width: 1140px;
  width: 100%;
}

.aboutPageSection3ContentCard {
  flex-basis: 50%;
}

.aboutPageSection3ContentCardTitle {
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
}

.aboutPageSection3ContentCardDescription {
  font-size: 16px;
  line-height: 28px;
}

.aboutPageSection3ContentList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.aboutPageSection3ImageContainer {
  flex-basis: 50%;
  border-radius: 20px;
  overflow: hidden;
}

.aboutPageSection3Image {
  width: 100%;
  height: auto;
}

.aboutPageSection2,
.aboutPageSection3,
.aboutPageSection4,
.aboutPageSection5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  max-width: 1140px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

.aboutPageSection4 {
  align-items: flex-start;
}

.aboutPageSection4AccordionContainer {
  flex-basis: 50%;
  width: 100%;
  border-top: 3px solid #2f80ed;
  padding: 0 20px;
  box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08),
    inset 0px 3px 0px 0px rgba(47, 128, 237, 0.004);
  border-radius: 20px;
}

.accordionItem {
  padding-top: 20px;
  padding-bottom: 20px;
}

.accordionItemTitle {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.accordionItemDescription {
  font-size: 16px;
  padding-top: 12px;
}
.aboutPageSection4Card {
  flex-basis: 50%;
}

.aboutPageSection4Card {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.aboutPageSection4CardTitle {
  font-size: 24px;
  font-weight: 600;
}

.aboutPageSection4CardDescription {
  font-size: 16px;
  line-height: 28px;
}

.aboutPageSection5Container {
  flex-basis: 50%;
}

.aboutPageSection5FirstContainer {
  background-color: #005ad3;
}

.aboutPageSection5SecondContainer {
  background-color: #0151bd;
}

.aboutPageSection5 {
  gap: 0;
  padding: 0;
}

.aboutPageSection5Container {
  padding: 60px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.aboutPageSection5ContainerTitle {
  color: white;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

.aboutPageSection5ContainerDescription {
  color: white;
}

.aboutPageSection5Button {
  border-style: solid;
  border-color: #1877a2;
  width: fit-content;
  background-color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 0 30px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: #1877a2;
}

.aboutPageSection5Button:hover {
  color: #1b6183;
  border-color: #1b6183;
}

@media only screen and (max-width: 768px) {
  .aboutPageHeaderContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .aboutPageHeaderTitle {
    line-height: 54px;
  }
  .aboutPageSection2,
  .aboutPageSection3,
  .aboutPageSection4 {
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
  }

  .aboutPageSection5 {
    flex-direction: column;
  }

  .aboutPageSection2Card {
    padding: 20px;
  }

  .aboutPageSection2Image {
    height: auto;
    width: 100%;
    max-width: 250px;
  }
}
