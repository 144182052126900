.infoContainer {
  display: flex;
  flex-direction: column;
  height: 40px;
  margin-top: 20px;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #252525;
  }

  .detailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .details {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
    .absenceTableActionButtons {
      display: flex;
      gap: 18px;

      .refreshContainer {
        display: flex;
        gap: 6px;
        align-items: center;

        .rvgcg50 {
          color: #1877a2 !important;
        }
      }
      .refreshButton,
      .addButton {
        display: flex;
        background: none;
        border: none;
        gap: 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        color: #1877a2;
        padding: 0;
        cursor: pointer;
      }

      .addButton {
        color: #242424;
      }
    }
  }
}

.absenceTableContainer {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 25px;
  overflow-x: auto;

  .absenceTable {
    width: auto;
    margin-bottom: 10px;

    .absenceTableHeaderRow {
      background-color: #eefafd !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #242424;
    }

    .f1p9o1ba {
      overflow-x: unset;
    }

    .fui-Avatar__initials {
      background-color: #fbebcc !important;
      font-size: 12px;
    }

    .absenceTableNameContainer {
      display: flex;
      flex-direction: column;
    }

    .absenceTablePeriodContainer {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }

    .absenceTableLabelSection {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .overlapIcon {
      cursor: pointer;
    }
    .absenceTableDepartmentSection {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }
    .absenceTablePeriodDaysContainer {
      background-color: #d4f2f9 !important;
      width: auto;
      padding: 0 8px 0 8px;
      height: 16px;
      border-radius: 100px;
      gap: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .absenceTablePeriodDays {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #1b6183;
    }
  }

  .absenceTableOverlapSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .absencesActionsCell {
    justify-content: end;
  }

  .absenceTableActionsSection {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: end;

    .actionIcon {
      cursor: pointer;
      justify-content: end;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.absenceTableContainer::-webkit-scrollbar {
  height: 7px;
}

.absenceTableContainer::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.absenceTableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}
