.app {
  display: flex;
  width: 100%;
  height: 100%;
}

.generalErrorContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  background-color: #fff;
  color: #333;
  padding: 0.4rem;
  border-bottom: 3px solid #f4f4f4;
}

.setupContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.content {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.details-content {
  flex: 1;
}

.main-content {
  background-color: #fff;
  color: #333;
  padding: 20px;
  overflow-y: auto;
  flex: 1;
}

.main-content {
  background-color: #fff;
  color: #333;
  flex: 1;
  padding: 20px;
}
