.detailedAttendanceTableActionButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  gap: 18px;

  .refreshContainer {
    display: flex;
    gap: 6px;
    align-items: center;

    .rvgcg50 {
      color: #1877a2 !important;
    }
  }
  .refreshButton,
  .addButton {
    display: flex;
    background: none;
    border: none;
    gap: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #1877a2;
    padding: 0;
    cursor: pointer;
  }

  .addButton {
    color: #242424;
  }
}

.detailedAttendanceTableContainer {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 25px;
  overflow-x: auto;

  .detailedAttendanceTable {
    width: auto;
    /* width: 500px; */
    margin-bottom: 10px;

    .detailedAttendanceHeaderRow {
      background-color: #eefafd !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #242424;
    }

    .f1p9o1ba {
      overflow-x: unset;
    }

    .fui-Avatar__initials {
      background-color: #fbebcc !important;
      font-size: 12px;
    }

    .detailedAttendanceNameContainer {
      display: flex;
      flex-direction: column;
    }

    .detailedAttendancePeriodContainer {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }

    .detailedAttendanceLabelSection {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .overlapIcon {
      cursor: pointer;
    }
    .detailedAttendanceDepartmentSection {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .detailedAttendanceActionsCell {
    justify-content: end;
  }

  .detailedAttendanceActionsSection {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: end;

    .actionIcon {
      cursor: pointer;
      justify-content: end;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.detailedAttendanceTableContainer::-webkit-scrollbar {
  height: 7px;
}

.detailedAttendanceTableContainer::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.detailedAttendanceTableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

@media only screen and (max-width: 768px) {
  .detailedAttendanceTableActionButtons {
    margin-top: 0 !important;
  }
}
