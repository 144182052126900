/* .attendancePageContainer {
  display: grid;
  gap: 12px;
} */
.attendancePageContainer {
  /* display: grid; */
  display: flex;
  flex-direction: column;
  gap: 12px;
}
