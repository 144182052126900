.trackLogContainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  background-color: #d4f2f9;
  border-radius: 4px;
  gap: 12px;
}

.trackLogTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #252525;
}

.trackLogDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  .trackLogInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .f1s2aq7o {
      color: #000;
    }
  }

  .trackLogLabel {
    font-weight: 400;
    font-size: 12px;
    color: #616161;
    padding-bottom: 7px;
  }

  .trackLogInput,
  .trackLogDatePickerInput {
    height: 33px;
    display: flex;
    gap: 20px;
    background-color: #fff !important;
    border: 1px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    border: none !important;
  }
  .trackLogInput {
    width: 142px;
  }

  .trackLogDatePickerInput {
    width: 105px;
    padding-left: 10px;
  }

  .trackLogDateInput {
    width: 100px;
    height: 32px;
    display: flex;
    align-items: center;
    background-color: #d4f2f9 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #dddddd !important;
  }

  .trackLogInput:focus,
  .trackLogDateInput:focus {
    outline: none;
    border-bottom: 2px solid #1877a2;
  }

  .datePickerContainer {
    position: relative;
  }

  .inputClock {
    position: absolute;
    top: 50%;
    right: 3px;
    transform: translateY(-50%);
    height: auto;
  }

  .hourDifference {
    max-width: 43px;
    height: 24px;
    border-radius: 4px;
    padding: 4px 8px 4px 8px;
    gap: 4px;
    background-color: #1877a2;
  }

  .hourDifferenceText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
  }

  .deleteTrackLogContainer {
    display: flex;
    align-items: center;
    height: 16px;
    border-radius: 100px;
    padding: 2px 6px 2px 6px;
    gap: 2px;
    background-color: #fde7e9;
    cursor: pointer;

    .deleteTextTrackLog {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #f1707b;
    }
  }
}

.trackLogFormContainer {
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  .trackLogFormContainer {
    width: 100%;
  }

  .trackLogContainer {
    height: auto;
    width: 100%;
    margin: 0;
  }

  .trackLogDetails {
    flex-direction: column !important;
  }
}
