.tacPage {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  max-width: 1000px;
}

.tacTitle {
  text-align: center;
  font-size: 24px;
  margin-bottom: 16px;
  line-height: 36px;
}

.tacSectionHeader {
  font-size: 20px;
}

.tacSectionSubheader {
  font-weight: 700;
}
