.step2TableContainer {
  overflow-x: auto;
}

.step2TableContainer::-webkit-scrollbar {
  height: 7px;
}

.step2TableContainer::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.step2TableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

.table {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.headerRow {
  background-color: #eefafd !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #242424;
}

.fui-Avatar__initials {
  background-color: #fbebcc !important;
  font-size: 12px;
}

.nameContainer {
  display: flex;
  flex-direction: column;
}

.positionTypeContainer {
  background-color: #eefafd !important;
  width: 62px;
  height: 16px;
  border-radius: 100px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.positionType {
  color: #1877a2;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.labelSection {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.departmentSection {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.positionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
