.absencesNavbar {
  background-color: #fff;
  color: #333;
  height: 50px;
  border-bottom: 2px solid #f4f4f4;
}

.absencesNavContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.absencesLogoContainer {
  display: flex;
  align-items: center;
}

.absencensInfoWrapper {
  display: flex;
  align-items: center;
  height: 48px;
  left: 20px;
  gap: 12px;
}

.absencesInfoContainer {
  display: flex;
  position: relative;
  width: auto;
  height: 48px;
  left: 20px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.absencesLogo {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.absencesActionContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 10px;
  width: auto;
}

.absencesActionSearchContainer {
  display: flex;
  gap: 12px;
}

.filterSection {
  display: flex;
  align-items: center;
  gap: 6px;
}

.filterSection:hover {
  cursor: pointer;
}

.absencesFilter {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #242424;
}

.absencesSearch {
  width: 210px;
  height: 32px;
  border-radius: 4px;
  background-color: #eefafd !important;
  border: none !important;
}

.importCsvButton {
  display: flex;
  height: 34px;
  border-radius: 4px;
  color: #fff !important;
}

.importCsvButtonName {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
}

.dragDropImportContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
  height: 400px;

  .detailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    gap: 8px;
  }

  .contentTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  .details {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
  }

  .deleteCsvButton {
    background: none;
    border: none;
    cursor: pointer;
  }

  .svgFileErrors {
    display: flex;
    align-items: center;
    max-width: 500px;
    border-radius: 4px;
    border: 1px solid #f3d6d8;
    padding: 6px 0px 6px 16px;
    gap: 4px;
    background-color: #fcf4f6;

    .errorText {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #c4314b;
    }

    .fui-Radio__indicator {
      border: 1px solid red !important;
    }
  }

  .actionButtons {
    display: flex;
    /* width: 337px; */
    height: 32px;
    gap: 8px;

    .downloadButton {
      gap: 8px;
    }

    .importCsvFile {
      border-radius: 4px;
      height: 32px;
      color: #ffffff;
      gap: 6px;
    }
  }
}

.actionButtons {
  display: flex;
  justify-content: end;
  height: 32px;
  gap: 8px;
}

@media only screen and (max-width: 768px) {
  .actionButtons {
    justify-content: center;
  }

  .absencesNavbar {
    height: auto !important;
  }

  .absencesActionContainer {
    flex-direction: column;
  }

  .absencesInfoContainer {
    left: 0 !important;
    height: auto !important;
  }

  .absencesNavContainer {
    flex-direction: column !important;
    padding: 6px !important;
  }
}

@media only screen and (max-width: 640px) {
  .absencesInfoContainer {
    flex-direction: column !important;
    gap: 4px !important;
  }
}
