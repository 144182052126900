.homePageContainer {
  width: 100%;

  .homePageHeader {
    background-color: #3ab3db;
    padding: 40px;
    border-radius: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .homePageHeaderText {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .homePageHeaderTextTitle {
    font-size: 64px;
    line-height: 75px;
  }

  .homePageHeaderTextContent {
    font-size: 24px;
    line-height: 32px;
  }

  .homeToggleContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .homeToggleBtn {
    background-color: #3ab3db;
    border: 1px solid #aaa;
    border-radius: 99px;
    width: 46px;
    height: 24px;
    cursor: pointer;
    position: relative;
  }

  .homeToggleBtn .thumb {
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 99px;
    transform: translateX(0);
    transition: left 0.15 ease;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
  }

  .unactive {
    background-color: #f0f0f0;
  }

  .homeToggleBtn.toggled .thumb {
    left: calc(50px - 27px);
  }

  .homePageDetailsContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .homePageChartContaier {
    min-width: 395px;
    height: 346px;
    padding: 30px;
    overflow: hidden;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .homePageChartTitle {
    font-size: 14px;
    font-weight: 700;
    color: rgba(37, 36, 36, 1);
  }

  .homePageChartContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 246px;
  }

  .homePageChartButton {
    font-size: 12px;
    color: #1877a2;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .homePageChartButton:hover {
    cursor: pointer;
  }

  .homePageAbsencesContainer {
    height: 346px;
    width: 100%;
    padding: 30px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .homeTimeOffTableNoApproval {
    font-size: 14px;
    font-weight: 600;
    color: rgba(37, 36, 36, 1);
  }

  .homeTimeOffTableContainer::-webkit-scrollbar {
    height: 7px;
  }

  .homeTimeOffTableContainer::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 4px;
  }

  .homeTimeOffTableContainer::-webkit-scrollbar-thumb:hover {
    background-color: #bfbfbf;
  }

  .homeTimeOffTableContainer {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    overflow-x: auto;

    .homeTimeOffTable {
      width: auto;
    }

    .f1p9o1ba {
      overflow-x: unset;
    }

    .homeTimeOffContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .homeTimeOffEmployeeSection {
      display: flex;
      gap: 8px;
    }

    .homeTimeOffNameContainer {
      display: flex;
      flex-direction: column;
    }

    .homeTimeOffLabelSection {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .homeTimeOffDepartmentSection {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }

    .homeTimeOffPeriodSection {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .homeTimeOffDaysContainer {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #1877a2;
      padding: 0 8px 0 8px;
      background-color: #eefafd !important;
      border-radius: 100px;
    }
  }
}

.rvgcg50 {
  color: #1877a2 !important;
}

.homeBottomHalf {
  width: 100%;
  max-width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 12px;
  justify-content: space-between;
  margin-top: 24px;
}

.homeCardContainer {
  width: 33%;
  max-width: 400px;
  /* height: 264px; */
  border-radius: 4px;
  gap: 4px;
  background-color: #fff;
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.11);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .homeContactUsCardImage {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  .homeCardInfoContainer {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;

    .homeCardTitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #242424;
    }

    .homeLinksContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .homeCardLink {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1877a2;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .homePageContainer {
    .homePageHeader {
      flex-direction: column;
      padding: 16px;
    }

    .homePageHeaderTextTitle {
      text-align: center;
    }

    .homePageHeaderTextContent {
      text-align: center;
    }

    .homePageHeaderImage {
      margin-top: 16px;
    }

    .homePageDetailsContainer {
      flex-direction: column;
    }

    .homePageChartContaier {
      min-width: 100%;
    }

    .homePageChartContaier,
    .homePageAbsencesContainer {
      padding: 16px;
    }
  }

  .homeBottomHalf {
    flex-direction: column;
    align-items: center;
  }

  .homeCardContainer {
    width: 100%;
  }
}
