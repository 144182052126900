.generalSettingsPage {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1000px;

  .generalSettingsInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #252525;
    }

    .detailsContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .details {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(97, 97, 97, 1);
      }
    }
  }
}

.settingsLanguageContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.settingsSelectInput {
  min-width: 212px;
  height: 32px;
  border-radius: 3px;
  color: #fff;
}
