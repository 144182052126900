.step3Container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
}

.step3HeaderContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.step3HeaderTextSection {
  flex-grow: 1;
}

.titleSection,
.selectRoleDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.step3Title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #242424;
  margin-bottom: 0;
}

.step3Subtitle,
.selectRoleSubtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #616161;
  margin-top: 0;
}

.selectRole {
  display: flex;
  flex-direction: column;
  gap: 150px;
}

.selectRoleTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .step3HeaderContainer {
    flex-direction: column;
  }
}
