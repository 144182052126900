.checkInModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 70px;
}

.checkInForm {
  margin: 20px 20px 20px 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .checkInInputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .checkInInputLabel {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #616161;
      margin-top: 12px;
    }

    .checkInSelectInput {
      width: 536px;
      height: 32px;
      border-radius: 3px;
      background-color: #eefafd !important;
    }

    .checkInInputStyle {
      display: flex;
      border-radius: 3px;
      gap: 20px;
      background-color: #eefafd !important;
      border: 1px;
      border-radius: 3px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #242424;
      border: none !important;
    }
  }

  .checkInButtonContainer {
    margin-top: 25px;
    display: flex;
    justify-content: end;
    .checkInButton {
      height: 32px;
      border-radius: 4px;
      padding: 0 12px 0 12px;
      gap: 4px;
      color: #fff !important;
    }
  }
  .fui-Select__select {
    background-color: #eefafd !important;
  }
}

@media only screen and (max-width: 768px) {
  .checkInForm {
    .checkInInputContainer {
      width: 100%;

      .checkInInputStyle {
        width: 100%;
      }
    }
  }
}
