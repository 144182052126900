.react-datepicker__day {
  display: inline-flex;
  width: 34px;
  border-radius: 24px !important;
  align-items: center;
  justify-content: center;
}
.react-datepicker__day--keyboard-selected {
  color: #000 !important;
  border-radius: 24px !important;
}
.react-datepicker__day--range-start {
  background-color: #1877a2 !important;
  border-radius: 24px !important;
}
.react-datepicker__day--in-range {
  background-color: #d4f2f9 !important;
  color: #000 !important;
  border-radius: none !important;
}
.react-datepicker__day--range-start {
  background-color: #1877a2 !important;
  color: #fff !important;
  border-radius: 24px !important;
}
.react-datepicker__day--range-end {
  background-color: #1877a2 !important;
  color: #fff !important;
  border-radius: 24px !important;
}
.react-datepicker__day--in-selecting-range {
  background-color: #d4f2f9 !important;
  color: #000 !important;
  border-radius: none !important;
}
.react-datepicker__day--selecting-range-start {
  background-color: #1877a2 !important;
  color: #fff !important;
  border-radius: 24px !important;
}
.react-datepicker__day--in-selecting-range-end {
  background-color: #1877a2 !important;
  border-radius: 24px !important;
}
.react-datepicker__day--today {
  background-color: #1877a2 !important;
  color: #fff !important;
  border-radius: 24px !important;
}

/* .react-datepicker__day {
    border-radius: 24px !important;
  } */
.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important;
}

.arrowButton {
  background-color: transparent;
  border: none;
  outline: none;
}

.headerDatePicker {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 15px;
}

.react-datepicker__day-name {
  width: 34px;
}

.infoMonthAndYear {
  padding: 7px 0 0 17px;
}

.selectDatePicker {
  width: auto;
  appearance: none;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
}

.infoMonthAndYear select:first-child {
  margin-right: 0;
}

.infoMonthAndYear select:last-child {
  margin-left: 0;
}
