.rolesAndPermissionsSettingsPage {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1000px;
  padding-bottom: 24px;

  .rolesAndPermissionsSettingsInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #252525;
  }

  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(36, 36, 36, 1);
  }

  .subdescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(97, 97, 97, 1);
  }

  .detailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .details {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(97, 97, 97, 1);
    }
  }
}

.settingsSyncNowBtn {
  margin-top: 10px;
  width: 111px;
  height: 32px;
  color: white !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.rolesAndPermissionsTableSectionHeader {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;
}

.rolesAndPermissionsAddRoleButton {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #fff !important;
}

.rolesAndPermissionsFilterSectionActions {
  display: flex;
  gap: 12px;
  align-items: center;
}

.rolesAndPermissionsSearchInput {
  width: 210px;
  height: 32px;
  border-radius: 4px;
  background-color: #eefafd !important;
  border: none !important;
}

.rolesAndPermissionsFilterSectionText {
  font-weight: 600;
  font-size: 14px;
  color: rgba(36, 36, 36, 1);
}

.rolesAndPermissionsTableContainer {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 25px;
  overflow-x: auto;
}

.rolesAndPermissionsTableContainerDefaultRolesTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #252525;
  margin-bottom: 16px;
}

.rolesAndPermissionsTable {
  width: auto;
}

.rolesAndPermissionsTableHeaderRow {
  background-color: #eefafd !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #242424;
}

.fui-Avatar__initials {
  background-color: #fbebcc !important;
  font-size: 12px;
}

.rolesAndPermissionsTableCellStyle {
  font-weight: 400;
  font-size: 14px;
  color: rgba(36, 36, 36, 1);
}

.rolesAndPermissionsTableContainer::-webkit-scrollbar {
  height: 7px;
}

.rolesAndPermissionsTableContainer::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.rolesAndPermissionsTableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

@media only screen and (max-width: 768px) {
  .rolesAndPermissionsTableSectionHeader {
    flex-direction: column;
    align-items: flex-end;
  }
}
