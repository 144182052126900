.filtersModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.filtersModal {
  background-color: #ffffff;
  padding: 20px 20px 0 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 4px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  min-width: 360px;
  position: relative;
}

.filtersModal::-webkit-scrollbar {
  width: 7px;
}

.filtersModal::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.filtersModal::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

.filtersModalHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.filtersModalTitle {
  font-size: 18px;
  font-weight: 700;
  color: rgba(50, 49, 48, 1);
}

.filters-modal-close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
}

.filterSectionTitle {
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterSectionTitleNumber {
  color: #1877a2;
}

.filtersModalContentSection {
  display: flex;
  flex-direction: column;
}

.filterSectionCheckboxesContainer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-top: 12px;
  padding-bottom: 12px;
}

.rl7ci6d {
  margin-left: 0px !important;
}

.filtersModalActionButtons {
  border-top: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  position: sticky;
  bottom: -2px;
  background-color: white;
  padding-bottom: 20px;
}

.filtersModalButtons {
  display: flex;
  gap: 8px;
}

.clearAllButton {
  font-size: 14px;
  font-weight: 400;
  color: #1877a2;
}

.clearAllButton:hover {
  cursor: pointer;
  color: #1b6183;
}

.applyButton {
  border-radius: 4px !important;
  height: 32px !important;
  color: #ffffff !important;
  gap: 6px;
}

.filtersSeeMoreBtn {
  font-size: 14px;
  font-weight: 400;
  color: rgba(91, 95, 199, 1);
}

.filtersSeeMoreBtn:hover {
  cursor: pointer;
}

.filtersIntervalPicker {
  margin-top: 10px;
  width: 262px;
  height: 32px;
  padding-left: 10px;
}
.filtersIntervalPicker:focus {
  outline: none;
  border-bottom: 2px solid #1877a2;
}

.filtersIntervalPickerInputStyle {
  display: flex;
  border-radius: 3px;
  gap: 20px;
  background-color: #eefafd !important;
  border: 1px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
  border: none !important;
}

.filtersDatePickerSection {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
}

@media only screen and (max-width: 768px) {
  .filtersModal {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
