.simpleAbsencesNavbar {
  background-color: #fff;
  color: #333;
  height: 50px;
  border-bottom: 2px solid #f4f4f4;
}

.simpleAbsencesNavContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.simpleAbsencesLogoContainer {
  display: flex;
  align-items: center;
}

.simpleAbsencensInfoWrapper {
  display: flex;
  align-items: center;
  height: 48px;
  left: 20px;
  gap: 12px;
}

.simpleAbsencesInfoContainer {
  display: flex;
  position: relative;
  width: auto;
  height: 48px;
  left: 20px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.simpleAbsencesLogo {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

@media only screen and (max-width: 768px) {
  .simpleAbsencesNavbar {
    height: auto !important;
  }

  .simpleAbsencesInfoContainer {
    left: 0 !important;
    height: auto !important;
  }

  .simpleAbsencesNavContainer {
    padding-left: 8px;
  }
}
