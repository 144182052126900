.welcome {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.step4Narrow {
  max-width: 80vh;
  height: 80vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-padding {
  padding: 4rem;
}

.welcome.page > .step4Narrow > img {
  margin: 0 auto;
  display: block;
  width: 200px;
}

.center {
  text-align: center;
}

.welcomeMessage {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.finishSection {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.completeMessage {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
