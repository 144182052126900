.side-nav {
  background-color: #fff;
  color: #333;
  width: 209px;
  top: 20px;
  left: 8px;
  border-right: 2px solid #f4f4f4;
}

.side-nav-ul {
  list-style-type: none;
  padding-top: 25px;
}

.side-nav-li {
  width: 209px;
  height: 32px;
  border-radius: 3px;
  top: 5px;
  left: 45px;
  position: relative;
  color: #cbc9c7;
  font-weight: 400;
}

.step {
  font-size: 14px;
  line-height: 20px;
  left: 28px;
}

.completed {
  position: relative;
  color: #484644;
}

.completed::before {
  content: url("../../../public/assets/images/initialSetup/check.svg");
  position: absolute;
  left: -20;
  top: 4;
}

.currentStep {
  width: 209px;
  height: 32px;
  border-radius: 3px;
  top: 5px;
  left: 45px;
  position: relative;
  color: #484644;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .side-nav {
    display: none;
  }
}
