.supportContainer {
  min-width: 279px;
  width: 279px;
  height: 204px;
  border-radius: 2px;
  border: 1px;
  padding: 16px;
  background-color: #d4f2f9 !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #252423;
  border-top: 3px solid #3ab3db !important;
}

.supportHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.supportTitle {
  padding: 0px;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.needHelpTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 5px 0 0 0;
}

.supportInfo {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #484644;
  margin: 0 0 10px 0;
}

.infoDetails {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.info {
  display: flex;
  flex-direction: row;
  gap: 8px;
  text-decoration: none;
  color: inherit;
}

.infoSpecial {
  margin-left: 6px;
}

.infoSpecialPhoneNumber {
  margin-left: 5px;
}

a {
  text-decoration: none;
  color: inherit;
}
