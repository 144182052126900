.ownHrComponent {
  margin-top: 48px;
  width: 100%;
  box-sizing: border-box;
}

.ownHrTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.ownHrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 12px 0;
  gap: 12px;
}
.usersSection {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  width: 254px;
  height: auto;
  border-radius: 4px;
  gap: 4px;
}

.image {
  max-width: 512px;
  width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
}

.usersContainer {
  width: 254px;
  height: auto;
  padding: 8px 16px 8px 16px;
  gap: 12px;
}

.cards {
  gap: 12px;
}

.cards .PersonCard:last-child {
  border-bottom: none;
}

@media (min-width: 768px) {
  .ownHrContainer {
    flex-direction: row;
    justify-content: space-around;
  }

  .imageContainer,
  .usersContainer {
    max-width: none;
    width: 48%;
  }
}
