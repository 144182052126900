.settingsLayout {
  display: flex;
  flex-direction: column;
}

.settingsContentLayout {
  display: flex;
}

.settingsMainContent {
  background-color: #fff;
  color: #333;
  flex: 1;
  padding: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .settingsMainContent {
    width: 100%;
    padding-top: 50px;
  }

  .settingsLayout {
    height: 100%;
  }
}
