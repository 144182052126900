.panels {
  max-width: max-content;
}

.attendancesTableContainer {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .attendancesTableTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #252525;
  }

  .attendancesTableSubtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000;
  }

  .tabListContainer {
    width: 298px;
    margin-left: -10px;
  }
}

.panels th {
  text-align: left;
  padding: 0 30px 0 0;
}

.panels td:first-child {
  font-weight: 600;
}

.panels td {
  padding: 0 30px 0 0;
}

.selectedTab span {
  color: #1877a2 !important;
}

.selectedTab::after {
  background-color: #1877a2 !important;
}

@media only screen and (max-width: 768px) {
  .attendancesTableContainer {
    .tabListContainer {
      margin-bottom: 12px;
    }
  }
}
