.addRoleModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 70px;
  min-width: 500px;
}

.addRoleForm {
  margin: 20px 20px 20px 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.addRoleFormField {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.addRoleFieldLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(97, 97, 97, 1);
}

.addRoleInputStyle {
  display: flex;
  min-width: 182px;
  height: 32px;
  border-radius: 3px;
  gap: 20px;
  background-color: #eefafd !important;
  border: 1px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
  border: none !important;
}

.addRoleSubmitButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}

.addRoleSubmitButton {
  height: 32px;
  border-radius: 4px;
  padding: 0 12px 0 12px;
  gap: 4px;
  color: #fff !important;
}

.addRoleRadioLabelContainer {
  font-size: 14px;
  font-weight: 400;
  color: rgba(104, 104, 104, 1);
}

@media only screen and (max-width: 768px) {
  .addRoleModalHeader {
    min-width: 100%;
  }
}
