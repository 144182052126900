.selectRoleDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 105px;
  height: 26px;
  border-radius: 4px !important;
  gap: 4px;
  background-color: #ebebeb !important;
  border: none !important;
  outline: none !important;
}

.fui-Dropdown__button {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #616161 !important;
}

.dropdownIcons {
  display: flex;
  gap: 6px;
}

.selectRoleDropdown::after {
  border-bottom: none !important;
  outline: none !important;
}
