.contactUsPageContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 40px; */
}

@media screen and (max-width: 800px) {
  .contactUsPageContainer {
    flex-direction: column;
    gap: 40px;
  }
}