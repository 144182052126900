.menuItemIcon {
  cursor: pointer !important;
}

.menuPopover {
  transform: translate(72px, 48px) !important;
}

.menuItemNavigationName {
  position: absolute;
  top: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
}

.menuItemNavigationLink {
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 16px;
}
