.simpleLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentSimpleLayout {
  display: flex;
  width: 100%;
  background-color: #fff;
  color: #333;
  flex: 1;
  padding: 30px;
}

.contentSimpleLayout.about {
  background-color: #d4f2f9;
  padding: 0;
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .contentSimpleLayout {
    padding: 12px;
  }
}
