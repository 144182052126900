.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 4px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.modal-close-button {
  display: flex;
  position: relative;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
}

.modalHeaderContainer {
  display: flex;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  height: 70px;
}

.logoAndTitleContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  left: 18px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.modalTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #252423;
}

.modalSubtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #616161;
}

.targoLogo {
  width: 40px;
}

.modalContentTitle {
  width: auto;
  border-radius: 3px;
  border-bottom: 3px solid #3AB3DB;
  display: flex;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}

.modalContentTitleName {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #252423;
  display: flex;
  position: relative;
  left: 12px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .modalOverlay {
    /* position: absolute; */
    overflow: hidden;
    bottom: 0;
    right: 0;
  }
  .modal {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    padding: 0px;
    border-radius: 0px;
  }

  .modalHeader {
    width: 100%;
  }
}
