.usersSettingsPage {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1000px;

  .f1p9o1ba {
    overflow-x: unset !important;
  }

  .usersSettingsInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #252525;
  }

  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(36, 36, 36, 1);
  }

  .subdescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(97, 97, 97, 1);
  }

  .detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;

    .details {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(97, 97, 97, 1);
    }
  }
}

.usersTotalSeatsContainer {
  margin-top: 12px;
  display: flex;
  max-width: 632px;
  width: 100%;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}

.usersSeatsTotalLabel {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
}

.usersSeatsTotalInput {
  width: 294px;
  height: 32px;
  border-radius: 4px;
  background-color: #eefafd !important;
  border: none !important;
  color: rgba(0, 0, 0, 1) !important;
}

.usersActionsContainer {
  margin-top: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.usersAddUsersButton {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #fff !important;
  min-width: 184px;
}

.usersSyncNowButton {
  border-radius: 4px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.usersFilterSectionActions {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.usersFilterSectionActionButtons {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.userTableActionsContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: end;
  margin-top: 16px;
}

.usersFilterSection {
  display: flex;
  gap: 4px;
  align-items: center;
}

.usersFilterSectionText {
  font-weight: 600;
  font-size: 14px;
  color: rgba(36, 36, 36, 1);
}

.usersSearchInput {
  width: 210px;
  height: 32px;
  border-radius: 4px;
  background-color: #eefafd !important;
  border: none !important;
}

.usersTableContainer {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 8px;
  overflow-x: auto;
}

.usersTable {
  width: auto;
}

.usersTableHeaderRow {
  background-color: #eefafd !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #242424;
}

.fui-Avatar__initials {
  background-color: #fbebcc !important;
  font-size: 12px;
}

.usersNameContainer {
  display: flex;
  flex-direction: column;
}

.usersNameLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(36, 36, 36, 1);
}

.usersDepartmentLabel {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(36, 36, 36, 1);
}

.dropdownIcons {
  display: flex;
  gap: 6px;
}

.usersTableContainer::-webkit-scrollbar {
  height: 7px;
}

.usersTableContainer::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 4px;
}

.usersTableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

@media (max-width: 768px) {
  .usersSettingsPage {
    max-width: 100%;
  }

  .usersTotalSeatsContainer {
    max-width: none;
  }

  .usersSeatsTotalInput {
    width: 100%;
  }

  .usersActionsContainer {
    justify-content: center;
  }

  .userTableActionsContainer {
    flex-direction: column;
  }
}
